import React, {Component} from "react";
import {graphql} from "gatsby";
import {Modal, ModalHeader} from "reactstrap";
import Layout from "../components/Layout";
import ondra_and_prchal from "../images/ondra a prchal.jpg";
import audience from "../images/audience.jpg";
import Event from "../components/Event";
import NewsLatter from "../components/NewsLatter";

class Historie extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: false,
			modalMessage: "",
			isSubmitting: false,
			isLightBoxOpen: false,
			events: props.data.allEventsJson.edges.map(edge => edge.node),
			currentGalleryPhoto: 1
		};
		this.toggle = this.toggle.bind(this);
		this.modalMessage = "";
	}

	componentDidMount() {
		document.title = "AppParade | Historie";
	}

	toggle() {
		this.setState({
			modal: !this.state.modal
		});
	}

	render() {
		return (
			<Layout>
				<Modal
					isOpen={this.state.modal}
					toggle={this.toggle}
					className={this.props.className}
				>
					<ModalHeader toggle={this.toggle}>
						{this.state.modalMessage}
					</ModalHeader>
				</Modal>
				{this.state.events.map(event => (
					<Event event={event}/>
				))}

				<hr/>
				<div className="container-heading">
					<h2><span className="slash"></span> Historie naší přehlídky</h2>
				</div>
				<hr/>

				<div className="container font-flexa-light" style={{marginBottom: 0}}>
					<div className="row">
						<div className="col">
							<p className="text-lg">
								AppParade se koná od prosince 2010, a to vždy v pondělí. Už jsme
								se mohli vidět třicetkrát a vybrat tak 30 nejlepších aplikací
								pro smartphony a tablety.
							</p>
						</div>
					</div>
				</div>

				<div className="container-fluid mt-10 mb-10">
					<div className="row justify-content-center list-of-images rounded-corner">
						<div className="col-sm-12 col-12 col-md-5 text-center">
							<img
								className="img-fluid"
								src={ondra_and_prchal}
								alt="Ondra a Prchal"
							/>
						</div>
						<div className="col-sm-12 col-12 col-md-5 text-center">
							<img className="img-fluid" src={audience} alt="publikum"/>
						</div>
					</div>
				</div>

				<div className="container font-flexa-light" style={{marginTop: 0}}>
					<div className="row">
						<div className="col">
							<p className="text-lg">
								V roce 2018 jsme převzali organizaci akce my – SYNETECH –, aby o
								ni česká developerská scéna nepřišla. A máme novinky!
							</p>
							<p className="text-lg">
								Od teď bude každá akce zaměřená na téma, které si sami vyberete.
								Kromě soutěže o nejlepší appku vás čekají tematické přednášky a
								diskuze o trendech v oboru. Věříme, že tak posuneme dopředu
								nejen sebe, ale i celé odvětví.
							</p>
							<p className="text-lg">Na viděnou ve Vnitroblocku!</p>
						</div>
					</div>
					{" "}
				</div>

				<hr/>
				<div className="container-heading">
					<h3><span className="slash"></span> Newsletter</h3>
				</div>
				<hr/>

				<NewsLatter
					onError={error => {
						throw error;
					}}
					modalClassName={this.props.className}
				/>
			</Layout>
		);
	}
}

export default Historie;
export const query = graphql`
	{
		allEventsJson(
			sort: { fields: [title], order: DESC }
			filter: { phase: { eq: "3" } }
		) {
			edges {
				node {
					title
					paragraphs
					images {
						childImageSharp {
							fluid {
								...GatsbyImageSharpFluid
							}
						}
					}
					applications {
						app_name
						company_name
						icon {
							childImageSharp {
								fixed(width: 50, height: 50) {
									...GatsbyImageSharpFixed
								}
							}
						}
					}
					cena_creative_docku {
						app_name
						company_name
						icon {
							childImageSharp {
								fixed(width: 50, height: 50) {
									...GatsbyImageSharpFixed
								}
							}
						}
					}
					jury
					phase
				}
			}
		}
	}
`;
