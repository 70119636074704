import React, { Component } from "react";
import ImgWrapper from "gatsby-image-wrapper";
import Lightbox from "react-images";

class LightBoxGallery extends Component {
	constructor(props) {
		super(props);
		this.state = {
			images: props.images,
			title: props.title,
			isLightBoxOpen: false
		};
	}

	render() {
		return (
			<div className="row justify-content-center list-of-images rounded-corner">
				{this.state.images.slice(0, 4).map((image, index) => (
					<div
						key={`${this.state.title}-image-${index}`}
						className="col-sm-12 col-12 col-md-2 text-center"
					>
						<a
							href={typeof image === "string" ? image : image.childImageSharp.fluid.src}
							onClick={e => {
								e.preventDefault();
								this.setState({
									currentGalleryPhoto: index,
									isLightBoxOpen: true
								});
							}}
						>
							<ImgWrapper
								data={image}
								className="img-fluid"
								alt={this.state.title}
							/>
						</a>
					</div>
				))}

				<Lightbox
					key={`${this.state.title}-lightbox`}
					backdropClosesModal
					images={this.state.images.map(image =>
						typeof image === "string" ? image : image.childImageSharp.fluid
					)}
					currentImage={this.state.currentGalleryPhoto}
					isOpen={this.state.isLightBoxOpen}
					onClickPrev={() =>
						this.setState({
							currentGalleryPhoto: this.state.currentGalleryPhoto - 1
						})
					}
					onClickNext={() =>
						this.setState({
							currentGalleryPhoto: this.state.currentGalleryPhoto + 1
						})
					}
					onClose={e => this.setState({ isLightBoxOpen: false })}
				/>
			</div>
		);
	}
}

export default LightBoxGallery;
