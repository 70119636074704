import React from "react";
import PropTypes from "prop-types";
import ImgWrapper from "gatsby-image-wrapper";
import LightBoxGallery from "./LightBoxGallery";

const Event = ({event}) => (
	<div key={event.title}>
		<hr/>
		<div className="container-heading">
			<h2 id={event.title}>
				<span className="slash"></span>
				{event.title}
			</h2>
		</div>
		<hr/>

		<div className="container font-flexa-light">
			<div className="row">
				<div className="col">
					{event.paragraphs.map((paragraph, index) => (
						<p className="text-lg" key={`${event.title}-text-${index}`}>
							{paragraph}
						</p>
					))}

					{event.applications[event.jury] && (
						<>
							<b className="mt-3">Cena Poroty</b>
							<br/>
							<div className="row">
								<div className="col-12">
									<ol className="list-of-choosed-apps">
										<li>
											<ImgWrapper data={event.applications[event.jury].icon} style={{minWidth: 50}}/>
											<div className="list-of-choosed-apps__text">
												<div>{event.applications[event.jury].app_name}</div>
												<div>{event.applications[event.jury].company_name}</div>
											</div>
										</li>
									</ol>
								</div>
							</div>
						</>
					)}

					<b className="mt-3">Cena Publika</b>
					<br/>

					<div className="row">
						<div className="col-12">
							<ol className="list-of-choosed-apps">
								{event.applications.map(application => (
									<li key={application.app_name}>
										<ImgWrapper data={application.icon} style={{minWidth: 50}}/>
										<div className="list-of-choosed-apps__text">
											<div>{application.app_name}</div>
											<div>{application.company_name}</div>
										</div>
									</li>
								))}
							</ol>
						</div>
					</div>

					{event.cena_creative_docku
						&& event.cena_creative_docku.icon
						&& event.cena_creative_docku.app_name
						&& event.cena_creative_docku.company_name && (
							<>
								<b className="mt-3">Cena Creative Docku</b>
								<br/>
								<div className="row">
									<div className="col-12">
										<ol className="list-of-choosed-apps">
											<li>
												<ImgWrapper data={event.cena_creative_docku.icon} style={{minWidth: 50}}/>
												<div className="list-of-choosed-apps__text">
													<div>{event.cena_creative_docku.app_name}</div>
													<div>{event.cena_creative_docku.company_name}</div>
												</div>
											</li>
										</ol>
									</div>
								</div>
							</>
						)}
				</div>
			</div>
		</div>

		{event.images && event.images.length > 0 && (
			<>
				<hr/>
				<div className="container-heading">
					<h2>
						<span className="slash"></span>
						{event.title}
						{"- galerie"}
					</h2>
				</div>
				<hr/>
				<div className="container-fluid mt-10 mb-10">
					<LightBoxGallery images={event.images} title={event.title}/>
				</div>
			</>
		)}
	</div>
);

Event.propTypes = {
	event: PropTypes.object.isRequired
};

export default Event;
